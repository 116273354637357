import { useEffect, useState, Fragment } from 'react'
import { AppStore, formatFieldValue, FormSchema, FormSchemaData, Storage } from 'stylewhere/shared'
import { Box, KeyValueRow, Button, FormSchemaForm } from 'stylewhere/components'
import { T, __ } from 'stylewhere/shared/i18n'
import { closeModal, openModal, sleep } from 'stylewhere/shared/utils'

interface Props {
  initData: FormSchemaData
  setFormData?: (newFormData: FormSchemaData) => void
  resetFormData?: (newFormData: FormSchemaData) => void
  formSchema: FormSchema
  detailStyle?: React.CSSProperties
  changeButtonStyle?: React.CSSProperties
  operationId?: string
  validateForm?: (data: FormSchemaData) => any
}

export const PageFormDetail: React.FC<Props> = ({
  formSchema,
  detailStyle,
  changeButtonStyle,
  initData,
  setFormData,
  resetFormData,
  operationId,
  validateForm
}) => {
  // Dobbiamo fare il preprocess dei della form perché non è detto che sia già stato fatto nel passaggio
  // start (per esempio, Inbound Warehouse non mostra la form nella Start)
  const [loading, setLoading] = useState(true)
  const [processedFormSchema, setProcessedFormschema] = useState<FormSchema>([])
  const [processedData, setProcessedData] = useState<FormSchemaData>([])
  useEffect(() => {
    const fetch = async () => {
      if (operationId) {
        await Storage.save('init-form-data-' + operationId, initData)
      }
      const processed = await FormSchemaForm.preprocessFormData({ schema: formSchema, initialValues: initData })
      setProcessedFormschema(processed.schema)
      setProcessedData(processed.initialValues)
      setLoading(false)
      AppStore.callbacks.openModalForm = openModalForm
    }
    fetch()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formSchema, initData])

  const openModalForm = (resolve?: () => void, reject?: () => void, submitText?, cancelText?) => {
    openModal({
      id: 'editForm',
      size: '3xl',
      body: (
        <Box center pt={7} pb={7}>
          <FormSchemaForm
            style={{ minHeight: 'auto', justifyContent: 'space-evenly', width: '100%' }}
            schema={formSchema.filter((schema) => !schema.disableChange)}
            initialValues={initData}
            submitText={submitText || __(T.misc.save)}
            cancelText={cancelText || __(T.misc.cancel)}
            cancelMarginTop={10}
            validateForm={validateForm}
            onSubmit={async (newData) => {
              setFormData?.(newData)
              await sleep(500)
              closeModal('editForm')
              resolve?.()
            }}
            onCancel={async () => {
              if (resetFormData && operationId) {
                const init = await Storage.load('init-form-data-' + operationId)
                resetFormData(init || initData)
                await sleep(500)
              }
              closeModal('editForm')
              reject?.()
            }}
            formContext="read"
          />
        </Box>
      ),
      closeOnOverlayClick: false,
    })
  }

  const hasEditableFields = formSchema.some(
    (schema) => !schema.disableChange && schema.type !== 'hidden' && schema.readOnly !== 'read'
  )

  return (
    <>
      {(processedFormSchema ?? formSchema).map((fs, fs_index) => (
        <Fragment key={fs.name + fs_index}>
          {fs.type !== 'hidden' ? (
            <KeyValueRow
              key={fs.name}
              variant="raw"
              label={fs.label}
              valueStyle={detailStyle}
              value={formatFieldValue(processedData ?? initData, fs)}
              loading={loading}
              style={{ paddingLeft: 0, paddingRight: 15 }}
            />
          ) : null}
        </Fragment>
      ))}
      {setFormData && hasEditableFields && (
        <Button style={changeButtonStyle} key="change" onClick={openModalForm} variant="secondary" size="default">
          {__(T.misc.change)}
        </Button>
      )}
    </>
  )
}
